import React, { useEffect } from 'react';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { startOfMonth, endOfMonth } from 'date-fns'; // Import date-fns functions

const DatePicker = ({ label, visibleRange, setVisibleRange = () => {}, ...props }) => {
    // Effect to update the visible range when the selected date changes
    useEffect(() => {
      if (props.value) {
        const start = startOfMonth(props.value);
        const end = endOfMonth(props.value);
        setVisibleRange({ start, end });
      }
    }, [props.value]);

    if (
      typeof props.value === 'undefined' ||
      typeof props.onChange !== 'function'
    ) {
      console.error('DatePicker requires both value and onChange props.');
      return null;
    }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        label={label}
        showToolbar={true}
        slotProps={{
          textField: {
            helperText: 'dd/mm/yyyy',
          },
          actionBar: {
            actions: ['clear'],
          },
        }}
        format="dd/MM/yyyy"
        {...props}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
