import React from 'react';
import { Form, Field } from 'react-final-form';
import { Grid2 } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TextInput from '../Text';

const useStyles = makeStyles((theme) => ({
  quantityInput: {
    backgroundColor: 'white',
  },
}));

const QuantityPicker = ({ value = 1, ...props }) => {
  const classes = useStyles();
  const validateField = (value) => {
    return undefined;
  };

  return (
    (<Grid2 className={classes.quantityInput}>
      <Form
        initialValues={{ Quantity: value }}
        onSubmit={() => {}}
        render={({ input, meta }) => (
          <Field
            {...input}
            name="Quantity"
            type="Number"
            setValidate={validateField}
            errorMessage={(value) => ''}
            parse={(value) =>
              !isNaN(value) && !/[eE]/.test(value) ? Number(value) : value
            }
            component={TextInput}
            value={value}
            placeholder="Quantity"
            setFormError={() => {}}
          />
        )}
      />
    </Grid2>)
  );
};

export default QuantityPicker;
