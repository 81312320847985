import {
    Grid2,
    Typography,
    Avatar,
    IconButton,
    ButtonBase,
  } from '@mui/material';
  import { useHistory } from 'react-router-dom';
  import { useEffect } from 'react';
  import { useStyles } from './styles';
  import { ROUTES } from 'core/routing/routes';
  import { useAuth } from 'core/contexts/auth';
  import EditIcon from 'assets/icons/edit.svg';
import { useTranslation } from 'react-i18next';


const ExperiecerData = () => {
  const name = 'Colorious';
  const description = '';
  const { t: translate } = useTranslation();
  
    const classes = useStyles();
    const history = useHistory();
  
    const { userInfo, getUserInfoSync } = useAuth();
  
    useEffect(() => { getUserInfoSync() }, []);
  
    return (
      <>
        <Grid2 container className={classes.userDataGrid} style={{background: `url(${userInfo?.host?.backgorundPictureURL}) no-repeat center center local`}}>
          <Grid2 size={{xs: 12, md: 1}} className={classes.userDataGrid}>
            <ButtonBase
              className={classes.container}
              onClick={() => history.push(ROUTES.PROFILE)}>
              <Avatar
                className={classes.avatar}
                src={
                  userInfo?.host?.pictureURL
                    ? `${process.env.REACT_APP_CDN_URL}${userInfo?.host?.pictureURL}`
                    : null
                }
              />
            </ButtonBase>
          </Grid2>
          <Grid2 size={{xs: 12, md: 8}} className={classes.userDataGrid}>
            <div className={classes.textContainer}>
              <div className={classes.nameContainer}>
                <Typography variant="h5" className={classes.text} noWrap>
                {translate('home.host.title')} {userInfo?.host?.name || name} !
                </Typography>
                {/* <IconButton
                onClick={() => history.push(ROUTES.PROFILE)}
                aria-label="edit"
                color="primary"
                className={classes.editButton}>
                <img alt="edit" style={{ width: '1em' }} src={EditIcon} />
              </IconButton> */}
              </div>
              {/* <Typography variant="h6" className={classes.text}>
                {userInfo?.experiencer?.description || description}
              </Typography> */}
            </div>
          </Grid2>
        </Grid2>
      </>
    );
  };
  
  ExperiecerData.displayName = 'ExperiecerData';
  
  export default ExperiecerData;
  