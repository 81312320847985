import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme, bgHost) => ({
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    border: `3px solid ${theme.palette.secondary.dark}`,
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
  },
  userDataGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      display: 'block',
    },
  },
  textContainer: {
    padding: `0px ${theme.spacing(8)}`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(2)} 0px`,
    },
  },
  text: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  nameContainer: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  editButton: {
    position: 'absolute',
    right: 0,
    top: -theme.spacing(),
  },
}));
