import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: '1em',
    minHeight: '68px',
  },
  content: {
    paddingBottom: theme.spacing(2),
  },
  card: {
    maxWidth: 300,
    marginTop: '3rem',
    padding: theme.spacing(4),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: 0,
  },
  submitButton: {
    marginTop: theme.spacing(),
  },
}));
