import { useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { AuthService } from 'core/api/services/auth';
import { ROUTES } from 'core/routing/routes';
import { useTranslation } from 'core/contexts/localization';

const VerifyCode = () => {
  const { t: translate } = useTranslation();

  const history = useHistory();
  const { code } = useParams();

  useEffect(() => {
    AuthService.verifyPassword(code)
      .then((valid) => {
        if (valid) {
          history.replace({
            pathname: ROUTES.FORGOTTEN_PASSWORD.RESET,
            state: { code },
          });
        } else {
          history.replace('/');
        }
      })
      .catch((err) => {
        console.log(err);
        history.replace('/');
      });
  }, []);

  return (
    <div
      style={{
        width: '15rem',
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
      }}>
      <h1 style={{ textAlign: 'center' }}>
        {translate('reset_password.verifying_code')}
      </h1>
      <LinearProgress color="primary" />
    </div>
  );
};

export default VerifyCode;
