import { useAuth } from 'core/contexts/auth';
import { useStyles } from './styles';

export const useAppBar = (isHome) => {
  const classes = useStyles();

  const { role, setRole, userInfo } = useAuth();

  const setUserRole = (selectedRole) => {
    setRole(selectedRole);
  };

  return {
    classes,
    role,
    setRole: setUserRole,
    // showBecomeButton,
    onBecomeClick: false,
  };
};
