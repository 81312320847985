import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, InputAdornment, TextField, IconButton } from '@mui/material';

const PaxInput = ({ label, value, onIncrement, onDecrement, ...props }) => (
    <TextField
        variant="outlined"
        size="large"
        style={{ marginBottom: '1rem' }}
        label={label}
        value={value}
        slotProps={{
            input: {
                startAdornment: (
                    <InputAdornment position="start">
                    <IconButton onClick={onDecrement} edge="start" size="small">
                        <RemoveIcon />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={onIncrement} size="small">
                          <AddIcon />
                          </IconButton>
                        </InputAdornment>
                ),
                inputProps: {
                    min: 1,
                    size: 20,
                    type: 'text',
                },
            }
        }}
        onWheel={(e) => e.target.blur()}
        onChange={(e) => {}}
    />
);

const PaxComponent = ({ adults, setAdults, setQuantity }) => {
    const incrementCount = (setter) => () => {
        setter((prevCount) => {
            const newCount = prevCount + 1;
            // setQuantity(newCount);
            return newCount;
        });
    };

    const decrementCount = (setter) => () => {
        setter((prevCount) => {
            const newCount = prevCount > 1 ? prevCount - 1 : 1; // Cambio aquí para asegurar que el valor no sea menor que 1.
            // setQuantity(newCount);
            return newCount;
        });
    };

    return (
        <Box>
            <PaxInput
                label="Adultos"
                value={adults}
                onIncrement={incrementCount(setAdults)}
                onDecrement={decrementCount(setAdults)}
            />
            {/* <PaxInput
        label="children"
        value={children}
        onIncrement={incrementCount(setChildren)}
        onDecrement={decrementCount(setChildren)}
      />
      <PaxInput
        label="discapacitados"
        value={dis}
        onIncrement={incrementCount(dis)}
        onDecrement={decrementCount(dis)}
      /> */}
        </Box>
    );
};

export default PaxComponent;
