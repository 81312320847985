import { Button } from '@mui/material';
import { useTranslation } from 'core/contexts/localization';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

const Buttons = ({ disabledSend, sendEmail }) => {
  const { t: translate } = useTranslation();

  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.buttons}>
      <Button className={classes.backButton} onClick={() => history.push('/')}>
        {translate('buttons.cancel')}
      </Button>
      <Button
        disabled={disabledSend}
        className="triper-button bg-blue-300"
        style={{ width: '7rem' }}
        onClick={() => {
          sendEmail();
        }}>
        {translate('reset_password.send_email')}
      </Button>
    </div>
  );
};

export default Buttons;
