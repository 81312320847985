import {
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { useStyles } from './styles';
import { useTranslation } from 'core/contexts/localization';

const ExperienceFilter = ({ experiences, onChange, values, onChangeAll }) => {
  const { t: translate } = useTranslation();
  const classes = useStyles();

  const renderItem = (index, onChange, text, checked) => {
    // TODO: when index === -1 then make something different in order to distinguish between the first and other items
    return (
      <MenuItem
        key={index}
        value={index}
        onClick={(event) => onChange(index)}
        style={{ whiteSpace: 'break-spaces' }}>
        <Checkbox checked={checked} />
        <ListItemText
          primary={text}
          style={{ color: 'black', wordBreak: 'break-word' }}
        />
      </MenuItem>
    );
  };

  const renderChangeAllCheckbox = () => {
    const checked = experiences.every((exp) => exp.checked === true);
    return renderItem(
      -1,
      () => onChangeAll(!checked),
      translate('home.select_all_experiences'),
      checked
    );
  };

  return (
    <FormControl variant="outlined" style={{ width: '20rem' }}>
      <style>
        {`
                    .MuiMenu-paper {
                        width: 20rem;
                        max-height: 20rem;
                    }
                `}
      </style>
      <InputLabel>{translate('home.experiences')}</InputLabel>
      <Select
        input={<OutlinedInput label={translate('home.experiences')} />}
        multiple
        value={values || []}
        renderValue={(selected) => {
          return selected.join(', ');
        }}
        style={{
          width: '20rem',
          color: 'black',
        }}
        MenuProps={{
          getContentAnchorEl: () => null,
          PaperProps: {
            style: {
              maxHeight: '20rem',
              width: '20rem',
            },
          },
        }}>
        {renderChangeAllCheckbox()}
        {experiences.map((exp, index) => {
          return renderItem(
            index,
            () => onChange(index),
            exp.name,
            exp.checked
          );
        })}
      </Select>
    </FormControl>
  );
};

ExperienceFilter.displayName = 'ExperienceFilter';

export default ExperienceFilter;
