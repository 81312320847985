import React, { useState } from 'react';
import { Grid2, Typography, Box } from '@mui/material';
import DatePicker from '../../../Input/DatePicker';
import Slots from '../Slots';
import {
  compareDates,
  formatDateToYYYYMMDD,
} from '../../../../utils/dateTimeFormatted';
import { useStyles } from './styles';
import { PickersDay } from '@mui/x-date-pickers';


const ExperienceDatePicker = ({
  showPrice = false,
  showAvailability = false,
  slots = [],
  quantity,
  datePicker, 
  experience,
  setSelectedSlot,
  setDatePicker,
  setVisibleRange,
  ...props
}) => {
  const classes = useStyles();
  const [selectedDay, setSelectedDate] = useState('');
  const CustomDay = ({ selectedDay, ...other }) => {
    const dayFormatted = formatDateToYYYYMMDD(other.day);
    // const selectedDayFormatted = formatDateToYYYYMMDD(selectedDate);

    // const slotsForDay = slots.find(slot => slot.date === dayFormatted);
    const slotsForDay = slots.filter(slot => slot.start?.substring(0,10) === dayFormatted);

    const dateToFo = formatDateToYYYYMMDD(selectedDay);
    setSelectedDate(dateToFo);
    
    let price = false;
    let availability = false;
    if(slotsForDay.length) {
        for (let i = 0; i < slotsForDay.length; i++) {
          const slot = slotsForDay[i];
          if(slot.available === true) {
            availability = true;
          }
          if(slot.price && (!price || (parseFloat(slot.price) > price))) {
            price = parseFloat(slot.price);
          } 
        }
    }

    // setSlotSelected(() => {
    //   return slots.filter((slot) => selectedDayFormatted === slot.start?.substring(0,10));
    // });

    let borderColor = classes.allDays;
    if (availability) {
      borderColor = classes.availabilityDay;
    }

    return (
      <Grid2 className={borderColor}>
        <Grid2>
            <PickersDay {...other}/>
        </Grid2>
        {showPrice ? (
          <Typography className={classes.customPrice}>
            {price ? `$${price}` : '\u00A0'}
          </Typography>
        ) : (
          <Typography className={classes.customPrice}>&nbsp;</Typography>
        )}
      </Grid2>
    )
  }
  

  return (
    <Grid2 className={classes.gridPadre}>
      <Box className={classes.gridDataPicker}>
          {/* The DatePicker component breaks if it doesn't receive the 'value' and 'onChange' props. 
             This is because it strictly requires these props for managing its internal state and updating 
             the selected date. Always ensure these props are passed to avoid errors */}
        <DatePicker
          slots={{ day: CustomDay }}
          value={datePicker}
          onChange={setDatePicker}
          onMonthChange={setDatePicker}
          setVisibleRange={setVisibleRange}
          slotProps={{
            actionBar: {
              actions: []
            },
            toolbar: {
              sx: {display: 'none'}
            },
            calendarHeader: {
              disabled: true,
            },
            day: {selectedDay: datePicker}
          }}
          {...props}
        />
      </Box>
      <Box className={classes.slotsContainer}>
        <Slots
          experience={experience}
          quantity={quantity}
          slot={slots}
          daySelected={selectedDay}
          setSelectedSlot={setSelectedSlot}
        />
      </Box>
    </Grid2>
  );
};

export default ExperienceDatePicker;
