import overrides from './overrides';
import palette from './palette';
import typography from './typography';
import { createTheme, adaptV4Theme } from '@mui/material/styles';

export default createTheme(adaptV4Theme({
  palette,
  typography,
  overrides,
  inputs: {
    minHeight: 104,
  },
}));
