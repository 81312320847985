import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: '#dcdcdc',
    // height: '45vh',
    width: '40vw',
    borderRadius: '0.5rem',
    marginInline: 'auto',
    boxShadow: '6px 6px 7px -3px rgba(0,0,0,0.61)',
    [theme.breakpoints.down('md')]: {
      // height: '40vh',
      width: '92vw',
    },
  },
  titleSubtitleBox: {
    padding: '2rem 1rem 0 1rem',
  },
  children: {
    width: '100%',
    // display: "flex",
    margin: '0 auto',
  },
}));
