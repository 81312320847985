import SelectComponent from 'core/libs/core-ui/components/Input/Select';
import React from 'react';

const TimePicker = ({
    id,
    value,
    values,
    style,
    onChange = () => {},
    labelId,
    ...props
}) => {
    return (
        <SelectComponent
            id={id}
            labelId={labelId}
            value={value}
            values={values}
            style={style || { width: '100%', color: 'black' }}
            onChange={(event) => {
                onChange(event);
                if (props.onFieldChange) props.onFieldChange(event);
                if (props.input?.onChange) props.input.onChange(event);
            }}
            {...props}
        />
    );
};

export const TimePickerHour = ({
    id,
    labelId,
    value,
    onFieldChange,
    ...props
}) => {
    const hours = Array.from({ length: 24 }, (_, i) => ({
        id: i.toString().padStart(2, '0'),
        name: i.toString().padStart(2, '0'),
    }));

    return (
        <TimePicker
            id={id}
            labelId={labelId}
            values={hours}
            value={value}
            onChange={(event) => onFieldChange?.(event)}
            {...props}
        />
    );
};

export const TimePickerMinutes = ({
    id,
    labelId,
    value,
    onFieldChange,
    ...props
}) => {
    const minutesOptions = [
        { id: '00', name: '00' },
        { id: '15', name: '15' },
        { id: '30', name: '30' },
        { id: '45', name: '45' },
    ];

    return (
        <TimePicker
            id={id}
            labelId={labelId}
            values={minutesOptions}
            value={value}
            onChange={(event) => onFieldChange?.(event)}
            {...props}
        />
    );
};
