import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  inputBox: {
    fontSize: '1rem',
  },
  buttons: {
    marginTop: '1rem',
    display: 'inline-flex',
    gap: '1rem',
    position: 'absolute',
    right: '20vw',
  },
}));
