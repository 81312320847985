import { useEffect, useLayoutEffect, useState } from 'react';
import { Box } from '@mui/material';
import DataGrid from 'core/libs/core-ui/components/DataGrid';

const processData = (categories) => {
    for (let i = 0; i < categories.length; i++) {
        const cat = categories[i];
        cat.resources = cat.resources.length
            ? cat.resources.map((res) => ({
                  resourceId: res.id ?? res.resourceId,
                  resourceName: res.name ?? res.resourceName,
                  categoryId: cat.resourceCategoryId ?? cat.resourceCategoryId,
              }))
            : [];
        cat.categoryId = cat.resourceCategoryId;
        cat.categoryName = cat.name;
    }
    return categories;
};

const ResourceGrid = ({ resources, setResourceSelected, resourceSelected }) => {
    const [gridData, setGridData] = useState(processData(resources));
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([
        { columnId: 'categorys', width: 150 },
        { columnId: 'resources', width: 150 },
    ]);

    useEffect(() => {
        if (gridData?.length > 0) {
            setRows([...getHeaderRow(), ...getRows(gridData)]);
        } else {
            setRows(getHeaderRow());
        }
    }, [gridData]);

    useLayoutEffect(() => {
        const updateColumnWidths = () => {
            const containerWidth = document.getElementById('resource-grid-container').clientWidth;
            const newWidth = Math.max(containerWidth / columns.length, 150);
            setColumns(columns.map((col) => ({ ...col, width: newWidth })));
        };

        updateColumnWidths();
        window.addEventListener('resize', updateColumnWidths);
        return () => window.removeEventListener('resize', updateColumnWidths);
    }, [columns.length]);

    const handleSelectedResource = (resourceId, categoryId, isSelected) => {
        setResourceSelected((prev) => {
            let newState = [...prev];
            let categoryIndex = newState.findIndex((item) => Object.keys(item)[0] === categoryId.toString());

            if (isSelected) {
                if (categoryIndex === -1) {
                    newState.push({ [categoryId]: [resourceId] });
                } else {
                    newState[categoryIndex][categoryId].push(resourceId);
                }
            } else {
                if (categoryIndex !== -1) {
                    newState[categoryIndex][categoryId] = newState[categoryIndex][categoryId].filter((id) => id !== resourceId);
                    if (newState[categoryIndex][categoryId].length === 0) {
                        newState.splice(categoryIndex, 1);
                    }
                }
            }
            return newState;
        });
    };

    const getHeaderRow = () => {
        const headerRow = [
            {
                rowId: 'header',
                cells: [
                    { type: 'header', text: 'Category', style: { fontSize: '13px', justifyContent: 'center', fontWeight: 'bold' } },
                    { type: 'header', text: 'Resource', style: { fontSize: '13px', justifyContent: 'center', fontWeight: 'bold' } },
                ],
                height: 35,
            },
        ];
        return headerRow;
    };

    const getRows = (values) => {
        return values.map((val, idx) => {
            let resourcesOptions = val?.resources.map((resource) => ({ id: resource?.resourceId, label: resource?.resourceName || '' }));
            return {
                rowId: idx++,
                cells: [
                    { type: 'text', text: val?.categoryName || '' },
                    { type: 'multiSelect', options: resourcesOptions || [], categoryId: val?.categoryId },
                ],
                height: 35,
            };
        });
    };

    useEffect(() => {
        setGridData(processData(resources));
    }, [resources]);

    return (
        <Box id="resource-grid-container">
            <DataGrid rows={rows} columns={columns} handleSelectedResource={handleSelectedResource} />
        </Box>
    );
};

export default ResourceGrid;
