import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  container: {
    padding: '60px 40px',
    [theme.breakpoints.down('md')]: {
      padding: '60px 10px',
    },
  },
  header: {
    alignItems: 'center',
  },
  title: {
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)} 0px ${theme.spacing(
        2
      )}`,
    },
  },
  selectContainer: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('md')]: {
      padding: `0px ${theme.spacing(2)}`,
    },
  },
  experiencesTitle: {
    margin: `${theme.spacing(2)} 0px`,
    [theme.breakpoints.down('md')]: {
      padding: `0px ${theme.spacing(2)}`,
    },
  },
  globalBookingsTitle: {
    padding: '3rem 2rem',
  },
  globalBookingsFilter: {
    margin: '0 2rem',
    display: 'inline',
  },
  globalBookingsSearchBoxContainer: {
    display: 'inline',
    margin: '0 2rem',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '20rem',
    },
  },
  globalBookingsSearchBox: {
    [theme.breakpoints.down('md')]: {
      margin: '0.5rem 0 0 0',
    },
  },
  globalBookingsShowPrevious: {
    display: 'block',
    margin: '0.5rem 2rem',
    width: 'fit-content',
    cursor: 'pointer',
  },
}));
