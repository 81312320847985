import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    imageContainer: {
        position: 'relative',
        width: '65vw',
        maxHeight: '65vh',
        overflowY: 'auto',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: "white",
        background:
            'radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 75%)',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'block',
        margin: 'auto',
    },
}));