import React, { useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  clickedRow: {
    backgroundColor: 'lightgrey',  
   },
   tableBodyContainer: {
    cursor: 'pointer'
   },
   btnHover: {
    '&:hover': {boxShadow: 'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15)', zIndex: '2'}
   }
});

export default function BasicTable({
  columns,
  values,
  onClick,
  handleSort = () => {},
  orderBy = '',
  direction = 'asc',
  ...props
}) {
  const classes = useStyles();
  const [firstOnClick, setFirstOnClick] = useState({})

  const isSortEnabled = useMemo(() => {
    return handleSort && handleSort.toString() !== (() => {}).toString();
  }, [handleSort]);

  const handleClick = (event, id) => {
    if (typeof onClick !== 'function') return;

    if (!firstOnClick || firstOnClick.id !== id) {
      setFirstOnClick({ event, id });
    } else {
      onClick(event, id);
    }
  };

  const checkArrays = () => {
    if (!Array.isArray(columns) || columns.length === 0) {
      console.error('A table without columns was requested');
      return false;
    }
    if (!Array.isArray(values)) {
      values = [values];
    }
    return true;
  };

  const HeaderCellContent = ({ column }) => {
    const cellLabel = column.label || column.val;

    if (isSortEnabled && column.column) {
      return (
        <TableSortLabel
          active={orderBy === column.column}
          direction={orderBy === column.column ? direction : 'asc'}
          onClick={() => handleSort(column.column)}
        >
          {cellLabel}
        </TableSortLabel>
      );
    }

    return <>{cellLabel}</>;
  };

  return !checkArrays() ? null : (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size={props.size || 'small'}
        aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell align={col.align || 'left'} style={col.style || {}}>
                <HeaderCellContent column={col} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {values.length === 0 ? (
          <caption style={{ textAlign: 'center' }}>The table is empty</caption>
        ) : (
          <TableBody className={classes.tableBodyContainer}>
            {values.map((row) => (
              <TableRow
                onClick={(event) => handleClick(event, row?.id || row[0])}
                key={row?.id || row[0]}
                className={[firstOnClick && firstOnClick.id === (row?.id || row[0]) ? classes.clickedRow : '', classes.btnHover]}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {columns.map(({ val, align }) => (
                  <TableCell align={align || 'left'}>{row[val]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
