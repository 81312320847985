import { useState } from 'react';
import { ReactGrid } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import { Button, Checkbox, FormControlLabel, Grid2, Menu, MenuItem, Popover, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateCalendar } from '@mui/x-date-pickers';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { TimePickerHour, TimePickerMinutes } from 'core/libs/core-ui/components/Input/TimePicker';
const ButtonCell = ({ text, onClick, style, icon }) => (
    <Button onClick={onClick} sx={{ width: '100%', height: '100%', ...style }}>
        {icon ? <DeleteIcon /> : text}
    </Button>
);

const MultiSelectCell = ({ options, onSelect, selectedOptions }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionChange = (optionId, isSelected) => {
        onSelect(optionId, isSelected);
    };

    const selectedOptionsLabel =
        options
            .filter((option) => selectedOptions.includes(option.id))
            .map((option) => option.label)
            .join(', ') || 'Seleccionar';

    return (
        <>
            <Button onClick={handleClick} sx={{ width: '100%', height: '100%' }}>
                {selectedOptionsLabel}
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {options.map((option) => (
                    <MenuItem key={option.id}>
                        <FormControlLabel control={<Checkbox checked={selectedOptions.includes(option.id)} onChange={(e) => handleOptionChange(option.id, e.target.checked)} />} label={option.label} />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

const DatePickerCell = ({ value, label, handleDateChange, exp, ...props }) => {
    const valueDate = new Date(value);
    const [hour, setHour] = useState(value ? valueDate.getHours() : 12);
    const [minute, setMinute] = useState(value ? valueDate.getMinutes() : '00');
    const [anchorEl, setAnchorEl] = useState(null);
    const [valueParsed, setValueParsed] = useState(value || new Date());

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleTimeChange = (newDate) => {
        if (!Date.parse(newDate)) {
            return;
        } else {
            const updatedDate = new Date(newDate);
            updatedDate.setHours(hour);
            updatedDate.setMinutes(minute);
            setValueParsed(updatedDate);
            handleDateChange(updatedDate, exp?.rowId);
        }
    };

    return (
        <Grid2 container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%' }}>
            <Grid2 sx={{ width: '100%', height: '100%' }} display="flex" justifyContent={value !== undefined ? 'center' : 'flex-start'} alignItems="center" spacing={2}>
                {value !== undefined && (
                    <Typography variant="subtitle1" sx={{ fontSize: '13px', justifyContent: 'flex-start', fontWeight: 400 }} gutterBottom>
                        {String(label)}
                    </Typography>
                )}
                <Button
                    onClick={handleClick}
                    sx={{
                        width: '40px',
                        height: '40px',
                        padding: '8px',
                        borderRadius: '50%',
                        minWidth: '0px',
                    }}
                >
                    <CalendarTodayIcon fontSize="small" />
                </Button>
            </Grid2>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <DateCalendar views={['year', 'month', 'day']} value={value ? valueParsed : null} onChange={handleTimeChange} />
                <Grid2 padding={2} display="flex" spacing={1} justifyContent="center" alignItems="center" borderTop="1px solid #ccc" width="100%">
                    <TimePickerHour
                        id={'datePickerHour'}
                        label="Hora"
                        value={hour}
                        onFieldChange={(selected) => {
                            setHour(selected.id);
                        }}
                    />
                    <TimePickerMinutes
                        id={'datePickerMinutes'}
                        label="Minutos"
                        value={minute}
                        onFieldChange={(selected) => {
                            setMinute(selected.id);
                        }}
                    />
                </Grid2>
            </Popover>
        </Grid2>
    );
};

const DataGrid = ({ rows, columns, handleSelectedResource, handleChange, ...props }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectOption = (resourceId, categoryId, isSelected) => {
        setSelectedOptions((prev) => {
            const newSelectedOptions = isSelected ? [...prev, resourceId] : prev.filter((id) => id !== resourceId);

            handleSelectedResource(resourceId, categoryId, isSelected);
            return newSelectedOptions;
        });
    };

    const customCellTemplates = {
        button: {
            getCompatibleCell: (cell) => ({
                ...cell,
                type: 'button',
                text: cell.text || '',
                icon: cell.icon || false,
            }),
            render: ({ handleClick, exp, text, icon }) => (
                <ButtonCell
                    text={text}
                    onClick={() => {
                        handleClick(exp);
                    }}
                    icon={icon}
                />
            ),
        },
        multiSelect: {
            getCompatibleCell: (cell) => ({
                ...cell,
                type: 'multiSelect',
                options: cell.options || [],
                selectedOptions: cell.selectedOptions || [],
            }),
            render: ({ categoryId, options }) => <MultiSelectCell options={options} selectedOptions={selectedOptions} onSelect={(resourceId, isSelected) => handleSelectOption(resourceId, categoryId, isSelected)} />,
        },
        datePicker: {
            getCompatibleCell: (cell) => ({
                ...cell,
                type: 'datePicker',
                value: cell.value || null,
                label: cell.label || '',
                handleDateChange: cell.handleDateChange,
            }),
            render: ({ value, label, handleDateChange, exp, ...props }) => <DatePickerCell value={value} label={label} handleDateChange={handleDateChange} exp={exp} {...props} />,
        },
    };

    return <ReactGrid rows={rows} columns={columns} customCellTemplates={customCellTemplates} onCellsChanged={handleChange} {...props} />;
};

export default DataGrid;
