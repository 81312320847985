import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    shareButton: {
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        '& .MuiButton-label': {
            marginTop: "1px",
        },
    },
}));
