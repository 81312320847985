import React from 'react';
import { Grid2, Typography, Divider, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AlarmIcon from '@mui/icons-material/Alarm';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';

// TODO move this to libs
function isValidNumber(value) {
    return typeof value === 'number' && !isNaN(value);
}

const BookingSummary = ({ showErrorPrice, setShowErrorPrice, ...props }) => {
    let checkoutItems = props?.checkoutItems;
    if (!checkoutItems || !checkoutItems.length) {
        return;
    }
    let totalPrice = 0;
    let auxShowErrorPrice = false;
    checkoutItems.forEach((it) => {
        if (it.bundleId) {
            it.experiences?.forEach((ex) => {
                if (!ex.dateUTC && !isValidNumber(ex.price)) {
                    auxShowErrorPrice = true;
                } else {
                     if (ex.dateUTC && ex.price === null) { // ATM we dont support slotPrice in bundles...
                         totalPrice += ex.slotPrice;
                     } else if (!ex.dateUTC && ex.price === null) {
                        totalPrice += ex.defaultPrice;
                     } else {
                        totalPrice += ex.price
                     }
                }
            });
        } else {
            if (!it.dateUTC && !isValidNumber(it.defaultPrice)) {
                auxShowErrorPrice = true;
            } else {
                if (it.dateUTC) {
                    totalPrice += it.slotPrice;
                } else {
                    totalPrice += it.defaultPrice;
                }
            }
        }
    });
    setShowErrorPrice(auxShowErrorPrice);

    return (
        <>
            <div>
                <Divider variant="middle" />
                {checkoutItems.map((it) => {
                    if (it.bundleId) {
                        return (
                            <>
                                <div
                                    style={{
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                    }}>
                                    <Grid2 container alignItems="center">
                                        <Grid2 size="grow">
                                            <Typography
                                                gutterBottom
                                                variant="h4">
                                                {it.name}
                                            </Typography>
                                        </Grid2>
                                        <Grid2
                                            size="grow"
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center">
                                            <Grid2>
                                                <Button
                                                    startIcon={<DeleteIcon />}
                                                    variant="contained"
                                                    style={{
                                                        color: 'white',
                                                        backgroundColor: 'red',
                                                        marginLeft: '10px',
                                                        marginTop: '15px',
                                                    }}
                                                    onClick={() =>
                                                        props.onRemoveItem(it)
                                                    }>
                                                    REMOVER PAQUETE
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </Grid2>
                                    {it.experiences?.map((exp) => {
                                        return (
                                            <>
                                                <Grid2
                                                    container
                                                    alignItems="center"
                                                    style={{
                                                        paddingTop: '15px',
                                                    }}>
                                                    <Grid2 size={{sm: 4, xs: "grow"}}>
                                                        <Typography
                                                            gutterBottom
                                                            variant="h6">
                                                            {exp.name}
                                                        </Typography>
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body2">
                                                            by{' '}
                                                            {
                                                                exp.experiencerName
                                                            }
                                                        </Typography>
                                                    </Grid2>
                                                    <Grid2
                                                        size="grow"
                                                        container
                                                        direction="row"
                                                        alignItems="center"
                                                        justifyContent="flex-end">
                                                        <Grid2>
                                                            <Typography
                                                                gutterBottom
                                                                variant="h6">
                                                                <span
                                                                    style={{
                                                                        marginRight:
                                                                            '10px',
                                                                    }}>
                                                                    {MoneyFormattedUtils(
                                                                        exp.price === null && exp.dateUTC ? exp.slotPrice : exp.price === null && !exp.dateUTC ? exp.defaultPrice : exp.price
                                                                    )}
                                                                </span>
                                                                <span>
                                                                    {DateFormattedUtils(
                                                                        exp.dateUTC, null, {
                                                                            year: 'numeric',
                                                                            month: 'long',
                                                                            day: 'numeric',
                                                                            hour: 'numeric',
                                                                            minute: 'numeric',
                                                                            timeZone: exp.timezone || 'UTC',
                                                                        }
                                                                    )}
                                                                </span>
                                                            </Typography>
                                                        </Grid2>
                                                        <Grid2>
                                                            <Button
                                                                startIcon={
                                                                    <AlarmIcon />
                                                                }
                                                                variant="contained"
                                                                color="primary"
                                                                style={{
                                                                    marginLeft:
                                                                        '15px',
                                                                }}
                                                                onClick={() =>
                                                                    props.onChangeDate(
                                                                        exp
                                                                    )
                                                                }>
                                                                CAMBIAR FECHA DE
                                                                ACTIVIDAD
                                                            </Button>
                                                        </Grid2>
                                                    </Grid2>
                                                </Grid2>
                                            </>
                                        );
                                    })}
                                </div>
                                <Divider variant="middle" />
                            </>
                        );
                    }
                    return (
                        <>
                            <div
                                style={{
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                }}>
                                <Grid2 container alignItems="center">
                                    <Grid2 size="grow">
                                        <Typography gutterBottom variant="h4">
                                            {it.name}
                                        </Typography>
                                        <Typography
                                            color="textSecondary"
                                            variant="body2">
                                            by {it.experiencerName}
                                        </Typography>
                                    </Grid2>
                                    <Grid2
                                        size="grow"
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center">
                                        <Grid2>
                                            <Button
                                                startIcon={<DeleteIcon />}
                                                variant="contained"
                                                style={{
                                                    color: 'white',
                                                    backgroundColor: 'red',
                                                    marginLeft: '10px',
                                                }}
                                                onClick={() =>
                                                    props.onRemoveItem(it)
                                                }>
                                                REMOVER ACTIVIDAD
                                            </Button>
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                                <Grid2
                                    container
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    style={{ paddingTop: '5px' }}>
                                    <Grid2
                                        size={{sm: 7, xs: "grow"}}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="flex-end">
                                        <Grid2>
                                            <Typography
                                                gutterBottom
                                                variant="h6">
                                                <span
                                                    style={{
                                                        marginRight: '10px',
                                                    }}>
                                                    {it.dateUTC
                                                        ? MoneyFormattedUtils(
                                                              it.slotPrice
                                                          )
                                                        : isValidNumber(
                                                              it.defaultPrice
                                                          )
                                                        ? MoneyFormattedUtils(
                                                              it.defaultPrice
                                                          )
                                                        : 'Actividad requiere fecha'}
                                                </span>
                                                <span>
                                                    {DateFormattedUtils(
                                                        it.dateUTC, null, {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            timeZone: it.timezone || 'UTC',
                                                        }
                                                    )}
                                                </span>
                                            </Typography>
                                        </Grid2>
                                        <Grid2>
                                            <Button
                                                style={{ marginLeft: '15px' }}
                                                startIcon={<AlarmIcon />}
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                    props.onChangeDate(it)
                                                }>
                                                CAMBIAR FECHA DE ACTIVIDAD
                                            </Button>
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                            </div>
                            <Divider variant="middle" />
                        </>
                    );
                })}
                {showErrorPrice ? (
                    <>
                        <Grid2
                            container
                            style={{
                                marginTop: '15px',
                                border: '1px solid rgba(255, 0, 0, 1)',
                                borderRadius: '25px',
                            }}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}>
                            <Grid2>
                                <Typography variant="h4">
                                    {' '}
                                    Algunas actividades requieren fecha
                                    obligatoriamente para determinar el precio .
                                    . .{' '}
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </>
                ) : (
                    <>
                        <Grid2
                            container
                            style={{ marginTop: '15px' }}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}>
                            <Grid2>
                                <Typography variant="h4">
                                    TOTAL:
                                </Typography>
                            </Grid2>
                            <Grid2>
                                <Typography variant="h4">
                                    {MoneyFormattedUtils(totalPrice)} x{' '}
                                    {props.quantity} ={' '}
                                    {MoneyFormattedUtils(
                                        parseFloat(totalPrice) *
                                            parseInt(props.quantity)
                                    )}
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </>
                )}
            </div>
        </>
    );
};
export default BookingSummary;
