import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

export default function SkeletonBasic({divClases, ...props}) {
  const classes = useStyles();
  return (
    <div className={divClases || classes.root}>
      <Skeleton props />
    </div>
  );
}