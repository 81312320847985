import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Box,
    Button,
    Grid2,
    List,
    ListItem,
    IconButton,
    Typography,
    useMediaQuery
} from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckIcon from '@mui/icons-material/Check';
import ContactIcon from '@mui/icons-material/Contacts';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FullScreenIcon from '@mui/icons-material/ZoomOutMap';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import placeholder from 'assets/images/experiences/placeholder.png';

import TimeIcon from 'core/libs/core-ui/assets/icons/experience/time.svg';
import EnvironmentIcon from 'core/libs/core-ui/assets/icons/experience/environment.svg';
import CancellationPolicyIcon from 'core/libs/core-ui/assets/icons/experience/cancel.svg';
import IncludesPolicyIcon from 'core/libs/core-ui/assets/icons/experience/check.svg';

import { EXPERIENCE_DATA_TYPE } from 'core/libs/constants';

import Dialog from 'core/libs/core-ui/components/Dialog';
import FullScreenImage from 'core/libs/core-ui/components/FullScreenImage';
import ModusChip from 'core/libs/core-ui/components/Chip';
import CopyLinkButton from 'core/libs/core-ui/components/CopyLinkButton';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';

import {
    getTranslatedNameFromNumber as getEnvironmentTranslatedNameFromNumber,
    environmental_policies,
} from 'core/libs/import/EnvironmentalPolicies';
import {
    getTranslatedNameFromNumber as getCancellationPolicyTranslatedNameFromNumber,
    cancellation_policies,
} from 'core/libs/import/CancellationPolicies';
import {
    moods,
    getTranslatedName as getModusTranslatedName,
    isIdPresent as isModusIdPresent,
} from 'core/libs/import/Moods';
import {
    getTranslatedNameFromNumber as getIncludesPolicyTranslatedNameFromNumber,
    includes_policies,
} from 'core/libs/import/IncludesPolicies';

import { useStyles } from './styles';

const ExperienceDetail = ({
    experience,
    experienceContract,
    useTranslation,
    experienceService,
    ...props
}) => {
    const { t: translate, i18n } = useTranslation();
    const i18nLang = i18n.language;
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const classes = useStyles();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const [sliderIndex, setSliderIndex] = useState(0);
    const [openInFullScreen, setOpenInFullScreen] = useState(false);
    const [mediaUrls, setMediaUrls] = useState([{}]);

    const handleClickOpen = async (e) => {
        let media = await experienceService.getMediaById(experience.id);
        if (media.length === 0) {
            setMediaUrls([
                {
                    type: 1,
                    src: placeholder,
                },
            ]);
        }
        if (media.length > 0) {
            setMediaUrls(
                media.map((media) =>
                    media.type === 1
                        ? {
                            type: 1,
                            src: `${process.env.REACT_APP_CDN_URL}${media.src}`,
                        }
                        : { type: 3, src: media.src }
                )
            );
        }
    };

    useEffect(() => {
        handleClickOpen();
    }, []);

    const RenderIcon = ({ icon }) => {
        return (
            <Box component="img" src={icon} alt="Icon" width={20} height={20} />
        );
    };

    const formatEnvironmentalPolicies = (icon, number) => {
        if (number === 0) {
            return null;
        } else {
            const policyName = getEnvironmentTranslatedNameFromNumber(
                environmental_policies,
                number,
                i18nLang
            );

            return (
                <Box display="flex" alignItems="center">
                    <RenderIcon icon={icon} />
                    <List className={classes.experienceRecycleWrapper}>
                        {policyName.map((name, index) => (
                            <ListItem
                                className={classes.experienceRecycle}
                                key={index}>
                                {/* {name} */}
                                <ModusChip
                                    key={name}
                                    color="gray"
                                    className={classes.modusChip}
                                    label={name}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            );
        }
    };

    const formatCancellationPolicies = (icon, number) => {
        if (number === 0) {
            return '';
        } else {
            const cancellationName =
                getCancellationPolicyTranslatedNameFromNumber(
                    cancellation_policies,
                    number,
                    i18nLang
                );
            return (
                <Box display="flex" alignItems="center">
                    <RenderIcon icon={icon} />
                    <List className={classes.experienceCancellationWrapper}>
                        {cancellationName.map((name, index) => (
                            <ListItem
                                className={classes.experienceCancellation}
                                key={index}>
                                {name}
                            </ListItem>
                        ))}
                    </List>
                </Box>
            );
        }
    };

    const formatIncludesPolicies = (icon, number) => {
        if (number === 0) {
            return '';
        } else {
            let prefix = translate('libs.includes');
            const policyName = getIncludesPolicyTranslatedNameFromNumber(
                includes_policies,
                number,
                i18nLang
            );
            return (
                <Box display="flex" alignItems="center">
                    <RenderIcon icon={icon} />
                    <List className={classes.experienceIncludeWrapper}>
                        {policyName.map((name, index) => (
                            <ListItem
                                className={classes.experienceInclude}
                                key={index}>
                                {/* {`${prefix} ${name}`} */}
                                <ModusChip
                                    key={name}
                                    color="gray"
                                    className={classes.modusChip}
                                    label={name}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            );
        }
    };
    const formatAnticipationPolicy = (icon, number) => {
        let days = Math.floor(number / 1440);
        let hours = Math.floor((number - days * 1440) / 60);
        let minutes = number - days * 1440 - hours * 60;

        let anticipationText = '';
        if (days > 0)
            anticipationText += `${days} ${translate('libs.time.days')} `;
        if (hours > 0)
            anticipationText += `${hours} ${translate('libs.time.hours')} `;
        if (minutes > 0)
            anticipationText += `${minutes} ${translate('libs.time.minutes')} `;

        if (anticipationText === '') {
            anticipationText = translate('libs.anticipation.none');
        } else {
            anticipationText = `${translate(
                'libs.anticipation.prefix'
            )} ${anticipationText.trim()} ${translate(
                'libs.anticipation.suffix'
            )}`;
        }

        return (
            <Box display="flex" alignItems="center">
                <RenderIcon icon={icon} />
                <Box sx={{ml: 1}}>{anticipationText}</Box>
            </Box>
        );
    };
    const getCancellationPoliciesList = (additionalCancellationPolicies) => {
        const cancellationPolicies = additionalCancellationPolicies.map(
            (cancellationPolicy) => {
                let anticipationTimeText = '';
                const time = cancellationPolicy.time;
                const days = Math.floor(time / 1440);
                const hours = Math.floor((time - days * 1440) / 60);

                if (days > 0) {
                    anticipationTimeText = `${days} ${translate(
                        'libs.time.days'
                    )}`;
                }

                if (hours > 0) {
                    if (days > 0) {
                        anticipationTimeText += ` ${translate(
                            'libs.and'
                        )} ${hours} ${translate('libs.time.hours')}`;
                    } else {
                        anticipationTimeText = `${hours} ${translate(
                            'libs.time.hours'
                        )}`;
                    }
                }

                return (
                    <Typography variant="body2" component="p">
                        <ListItem className={classes.experienceCancellation}>
                            {`${cancellationPolicy.percentage}%
                             ${translate(
                                'cancellation_policies.ofTheValuePaidWith'
                            )}
                              ${anticipationTimeText} ${translate(
                                'cancellation_policies.ofAnticipation'
                            )}`}
                        </ListItem>
                    </Typography>
                );
            }
        );

        cancellationPolicies.unshift(
            <ListItem className={classes.experienceCancellation}>
                <Typography variant="body2" component="p">
                    <List className={classes.experienceCancellation}>
                        {translate('cancellation_policies.title')}:
                    </List>
                </Typography>
            </ListItem>
        );

        return (
            <Box
                display="flex"
                sx={{mt: 1}}
                className={classes.experienceCancellation}>
                <RenderIcon alignSelf="start" icon={CancellationPolicyIcon} />
                <ListItem className={classes.experienceCancellation}>
                    <List className={classes.experienceCancellation}>
                        {cancellationPolicies}
                    </List>
                </ListItem>
            </Box>
        );
    };

    const getPolicyIconRender = (icon, number, experienceDataType) => {
        switch (experienceDataType) {
            case EXPERIENCE_DATA_TYPE.ENVIRONMENTAL_POLICIES:
                return formatEnvironmentalPolicies(icon, number);
            case EXPERIENCE_DATA_TYPE.CANCELLATION_POLICIES:
                return formatCancellationPolicies(icon, number);
            case EXPERIENCE_DATA_TYPE.INCLUDES_POLICIES:
                return formatIncludesPolicies(icon, number);
            case EXPERIENCE_DATA_TYPE.ANTICIPATION:
                return formatAnticipationPolicy(icon, number);
            default:
                throw new Error('Invalid experienceDataType');
        }
    };

    const getSliderType = () => {
        return mediaUrls[sliderIndex].type;
    };

    const getSliderSrc = () => {
        return mediaUrls[sliderIndex].src;
    };

    const sliderNext = () => {
        if (sliderIndex === mediaUrls.length - 1) {
            setSliderIndex(0);
        } else {
            setSliderIndex(sliderIndex + 1);
        }
    };

    const sliderPrev = () => {
        if (sliderIndex === 0) {
            setSliderIndex(mediaUrls.length - 1);
        } else {
            setSliderIndex(sliderIndex - 1);
        }
    };

    const handleOpenInFullScreen = () => {
        setOpenInFullScreen(true);
    };

    const handleCloseInFullScreen = () => {
        setOpenInFullScreen(false);
    };

    return (<>
        <Box>
            <Grid2 className={classes.detailsWrapper}>
                <Grid2 className={classes.gridDetailsWrap} container>
                    <Grid2 size={{xs: 12, sm: 6, md: 6}}>
                        <Box className={classes.buttonsOverlay}>
                            <IconButton
                                size="medium"
                                onClick={handleOpenInFullScreen}
                                className={classes.fullScreenImageButton}>
                                <FullScreenIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                onClick={
                                    handleOpenInFullScreen
                                }
                                size="large"></IconButton>
                            <IconButton
                                onClick={sliderPrev}
                                size="medium"
                                className={`
                                    ${classes.imageButton}
                                    ${classes.imageRightButton}
                                `}>
                                <ChevronRight fontSize="large" />
                            </IconButton>
                            <IconButton
                                onClick={sliderNext}
                                size="medium"
                                className={` ${classes.imageButton}
                                    ${classes.imageLeftButton}`}>
                                <ChevronLeft fontSize="large" />
                            </IconButton>
                        </Box>
                        <Box className={classes.mainBox}>
                            {getSliderType() === 1 ? (
                                <img
                                    className={classes.imageStyle}
                                    src={getSliderSrc()}
                                />
                            ) : (
                                <iframe
                                    className={classes.iframeStyle}
                                    src={getSliderSrc()}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="fullscreen"></iframe>
                            )}
                        </Box>
                    </Grid2>
                    <Grid2
                        className={classes.descriptionWrapper}
                        size={{xs: 12, sm: 5, md: 5}}>
                        <Box sx={{mt: 3}} className={classes.copyLinkWrapper}>
                            <CopyLinkButton
                                experienceId={experience.id}
                                experienceName={experience.name}
                                contractCode={experienceContract}
                                label={translate(
                                    'libs.buttons.copylink'
                                )}
                            />
                        </Box>
                        <Box sx={{mt: 1}}>
                            <Typography
                                className={classes.experiencieTitle}
                                variant="h3">
                                {experience.name}
                            </Typography>
                            <Typography
                                className={classes.experiencieSubtitle}
                                variant="h5">
                                {experience.subtitle}
                            </Typography>
                        </Box>
                        <Box display="flex" sx={{mb: 1}}>
                            <Box>
                                <ContactIcon fontSize="small" />
                            </Box>
                            <Box alignSelf="center">
                                <Typography
                                    className={classes.experienciceName}>
                                    {experience.experiencerName}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.locationWrapper} sx={{mb: 1}}>
                            <Box>
                                <LocationOnIcon />
                            </Box>
                            <Box>{experience.location}</Box>
                        </Box>
                        <Box display="flex">
                            {experience.moduses.length > 0 &&
                                experience.moduses.some((m) =>
                                    isModusIdPresent(m, moods)
                                ) && (
                                    <Box
                                        sx={{mb: 1}}
                                        flexWrap="nowrap"
                                        display="flex">
                                        <Box className={classes.checkIcon}>
                                            <CheckIcon />
                                        </Box>
                                        <Box
                                            className={
                                                classes.modusesWrapper
                                            }>
                                            {experience.moduses.map(
                                                (modusId) => {
                                                    if (
                                                        !isModusIdPresent(
                                                            modusId,
                                                            moods
                                                        )
                                                    ) {
                                                        return null;
                                                    }

                                                    return (
                                                        <ModusChip
                                                            key={modusId}
                                                            color="gray"
                                                            className={
                                                                classes.modusChip
                                                            }
                                                            label={getModusTranslatedName(
                                                                modusId,
                                                                i18nLang,
                                                                moods
                                                            )}
                                                        />
                                                    );
                                                }
                                            )}
                                        </Box>
                                    </Box>
                                )}
                        </Box>
                        <Box display="flex">
                            <Box>
                                <AttachMoneyIcon />
                            </Box>
                            <Box>
                                <Typography variant="body2" component="p">
                                    <Typography variant="span">
                                        {`${translate(
                                            'experienceCard.fromPrice'
                                        )} ${experience.currency} ${MoneyFormattedUtils(
                                            experience.fromPrice,
                                            i18nLang,
                                        )}`}
                                    </Typography>
                                    <Typography
                                        className={
                                            classes.porpersonWrapper
                                        }>
                                        {translate(
                                            'experienceCard.perPerson'
                                        )}
                                    </Typography>
                                    {/* <p style={{ display: "inline", fontWeight: "bold" }}> {`${props.payNow}% ${translate("experienceCard.in_advance")}`}</p> */}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
                <Box sx={{mt: 5}}>
                    {getPolicyIconRender(
                        TimeIcon,
                        experience.anticipation,
                        EXPERIENCE_DATA_TYPE.ANTICIPATION
                    )}
                    {getPolicyIconRender(
                        EnvironmentIcon,
                        experience.envPolicies,
                        EXPERIENCE_DATA_TYPE.ENVIRONMENTAL_POLICIES
                    )}
                    {experience?.cancelPolicies?.nonRefundable
                        ? getPolicyIconRender(
                            CancellationPolicyIcon,
                            8,
                            EXPERIENCE_DATA_TYPE.CANCELLATION_POLICIES
                        )
                        : getCancellationPoliciesList(
                            experience.cancelPolicies.additionals
                        )}
                    {getPolicyIconRender(
                        IncludesPolicyIcon,
                        experience.includesPolicies,
                        EXPERIENCE_DATA_TYPE.INCLUDES_POLICIES
                    )}
                </Box>
                <Box className={classes.experienceDescription}>
                    {experience.description}
                </Box>
            </Grid2>
            <Grid2 size={{xs: 12}} className={classes.buttonWrapper}>
                <Button
                    autoFocus
                    onClick={() => history.push(`/booking/new/${experience.id}${experienceContract ? '/code/' + experienceContract : ''}`)}
                    className={classes.buttonBook}>
                    {translate('experienceCard.book')}
                </Button>
            </Grid2>
        </Box>
        <Dialog
            open={openInFullScreen}
            handleClose={handleCloseInFullScreen}
            hideActions={true}
            hideTitle={true}
            dialogContent={
                <Box>
                    <FullScreenImage
                        src={getSliderSrc()}
                        onClose={handleCloseInFullScreen}
                    />
                </Box>
            }
        />
    </>);
};

export default ExperienceDetail;
