import { TextField } from '@mui/material';

import { useTranslation } from 'core/contexts/localization';

const TextInput = ({
  meta: { touched, error },
  input: { ...inputProps },
  ignoreError = false,
  ...props
}) => {
  const { t: translate } = useTranslation();

  return (
    <TextField
      {...inputProps}
      {...props}
      onChange={(event) => {
        if (props.onFieldChange) props.onFieldChange(event.target.value);
        if (props.onTextFieldChange) props.onTextFieldChange(event);
        inputProps.onChange(event);
      }}
      error={!!(touched && error && !ignoreError)}
      helperText={touched && error && !ignoreError && translate(error)}
      fullWidth
      variant="outlined"
    />
  );
};

TextInput.displayName = 'TextInput';

export default TextInput;
