import makeStyles from '@mui/styles/makeStyles';
import bgExperiencer from 'assets/images/home-experiencer.jpg';
import palette from 'core/libs/core-ui/theme/colors-palette';
export const useStyles = makeStyles((theme) => ({
    content: {
        padding: 0,
    },
    container: {
        padding: `80px 60px 80px 100px`,
        backgroundColor: theme.palette.colors.white,
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(4)} ${theme.spacing(
                4
            )} ${theme.spacing(6)}`,
        },
    },
    gridContainer: {
        [theme.breakpoints.up('md')]: {
            background: `linear-gradient(
        rgba(255, 255, 255, 0.70), 
        rgba(255, 255, 255, 0.75)
      ), url(${bgExperiencer}) no-repeat center center local`,
        },
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(14)}px ${theme.spacing(
                4
            )}px ${theme.spacing(6)}px`,
        },
    },
    hostDataContainer: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        },
    },
    title: {
        height: theme.spacing(4),
    },
    experiencesContainer: {
        // [theme.breakpoints.down('sm')]: {
        //   padding: `80px 60px`,
        // },
    },
    experiencesTitle: {
        paddingBottom: '20px',
    },
    profile: {
        backgroundColor: 'rgb(0 68 142 / 18%)',
        color: 'rgb(0,68,142)',
    },
    newExperience: {
        backgroundColor: 'rgb(10 190 103 / 20%)',
        color: 'rgb(10,190,103)',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        width: '100%',
    },
    buttonText: {
        color: theme.palette.colors.white,
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    experienceListWrapper: {
        marginTop: '0.5rem',
    },
    experienceButtonWrapper: {
        alignSelf: 'center', // default alignment for xs screens
        [theme.breakpoints.up('md')]: {
            textAlignLast: 'end',
            marginTop: '2rem',
        },
        [theme.breakpoints.up('lg')]: {
            textAlignLast: 'end',
            marginTop: '2rem',
        },
    },
    newExperienceButton: {
        cursor: 'pointer',
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: palette.utriper.green,
        '&:hover': {
            backgroundColor: palette.utriper.darkgreen,
        },
    },
}));
