import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  customPrice: {
    fontSize: 7.5,
  },
  gridPadre: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: 'fit-content',
  },
  allDays: {
    '& .MuiPickersDay-root': {
      color: '#7d7987',
      fontWeight: '500 !important',
      fontSize: '12px !important',
      background: 'transparent',
    }
  },
  availabilityDay: {
    '& .MuiPickersDay-root': {
      color: '#fff',
      borderRadius: '18px',
      borderWidth: '0px',
      border: '0px solid',
      backgroundColor: '#12a14b',
    },
  },
  gridDataPicker: {
    marginTop: '20px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    '& .MuiDayCalendar-weekDayLabel': {
      color: '#7d7987',
      fontWeight: '500 !important',
      fontSize: '12px !important',
      background: 'transparent'
    },
    '& .Mui-selected': {
      color: '#7d7987',
      borderRadius: '18px',
      backgroundColor: 'lightblue !important',
      '&:hover': {
        backgroundColor: 'lightblue',
      }
    },
  },
  slotsContainer: {
    maxHeight: '16rem',
    overflowY: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: '1px solid slategrey',
    },
    '& scrollbar-color': 'darkgrey lightgrey',
    '& scrollbar-width': 'thin',
  },
}));
