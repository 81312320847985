import React from 'react';
import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  responsiveChip: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.5rem', 
    },
  },
}));

const ModusChip = ({ label, color, size = 'small', ...props }) => {
  const classes = useStyles();

  return (
    <Chip
      label={label}
      color={color}
      size={size}
      className={classes.responsiveChip}
      {...props}
    />
  );
};

export default ModusChip;
