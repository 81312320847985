import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 1),
    justifyContent: 'space-between',
    alignItems: 'center',
    ...theme.mixins.toolbar,
  },
  logo: {
    height: 56,
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  closedMenuLogo: {
    height: 32,
    margin: 'auto'
  },
  list: {
    height: '100%',
    color: 'white',
  },
  signOut: {
    position: 'absolute',
    bottom: 90,
    display: 'block',
  },
  openMenu: {
    // position: 'absolute',
    // bottom: 30,
    margin: 'auto',
    // background: '#4b4d58',
    textAlign: 'right',
    display: 'block',
  },

  copyright: {
    position: 'absolute',
    bottom: 4,
    padding: '4px 16px',
    fontSize: '0.75rem',
    color: theme.palette.colors.alto,
  },
  menuButton: {
    height: '40px',
    width: '40px',
    [theme.breakpoints.down('lg')]: {
      color: '#fff',
      background: '#001529',
      borderRadius: '0 4px 4px 0',
      boxShadow: '2px 0 8px rgb(0 0 0 / 15%)',
      position: 'fixed',
      top: '10px',
      left: 0,
      marginLeft: 0,
    },
  },
}));
