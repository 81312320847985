import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  backButton: {
    height: 40,
    borderRadius: 4,
    padding: '0px 20px',
    width: '7rem',
  },
  buttons: {
    marginTop: '1rem',
    display: 'inline-flex',
    gap: '1rem',
    position: 'absolute',
    right: '20vw',
  },
}));
