import { useEffect } from "react";
import { Grid2 } from "@mui/material";
import ExperienceCard from "./components/ExperienceCard";

const ExperiencesList = ({
  code,
  contractExperiences,
  useTranslation,
  experienceService,
  ...props
}) => {
  // const mdSize = contractExperiences?.length === 1 ? 6 : 3;

  return (
    <Grid2 container justifyContent="center" spacing={2}>
      {contractExperiences?.map((experience) => (
        <Grid2 key={experience.id} size={{xs: 12, sm: 6, md: 4, lg: 3}}>
          <ExperienceCard
            experience={experience}
            experienceContract={code}
            useTranslation={useTranslation}
            experienceService={experienceService}
          />
        </Grid2>
      ))}
    </Grid2>
  );
};

export default ExperiencesList;
