import React from 'react';
import { Grid2, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './styles';


const FullScreenImage = ({ src, open, onClose }) => {
    const classes = useStyles();


    return (
        (<div className={classes.root}>
            <Grid2 container className={classes.imageContainer}>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large">
                    <CloseIcon />
                </IconButton>
                <img src={src} alt="experience" className={classes.image} />
            </Grid2>
        </div>)
    );
};

export default FullScreenImage;
