import React, { useEffect } from 'react';
import {
    Grid2,
    Typography,
} from '@mui/material';

import ExperienceDatePicker from './components/ExperienceDatePicker';
import CircularIndeterminate from '../Progress';
import ResourceGrid from '../ResourceGrid';

import { useTranslation } from 'core/contexts/localization';

import { useLocation } from 'react-router-dom';
import PaxComponent from '../PaxComponente';
import useStyles from './style';

const ExperienceAvailabilityPageComponent = ({
    showLoading = false,
    showPrice = false,
    showTitle = true,
    showQuantity = true,
    quantity,
    // value, // DEPRECATED: ahora este valor es datePicker
    datePicker, // NEW: se cambio el nombre de value a datePicker
    resources = [],
    // data, // DEPRECATED
    slots, // NEW: se le cambio el nombre de data a slots
    experience,
    // handleQuantityChange = () => {}, //DEPRECATED: Se elimino handleQuantityChange y se agrego setQuantity
    setQuantity,
    // onChange = (date) => {}, //DEPRECATED: se elimino onChange y se agrego setDatePicker
    setDatePicker,
    setVisibleRange,
    // onMonthChange = (date) => {}, //DEPRECATED
    // addBookedExperienceWithQuantityPrice, //DEPRECATED
    // setTimeOfArrival, //DEPRECATED
    // onSlotSelect = (slot) => {}, //DEPRECATED
    setSelectedSlot,
    setResourceSelected,
    // setDialogOpen, // DEPRECATED
    resourceSelected,
    ...props
}) => {
    const classes = useStyles();
    const { t: translate } = useTranslation();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const urlDate = searchParams.get('date');
    const urlQuantity = searchParams.get('quantity');
    useEffect(() => {
        if (urlQuantity) {
            setQuantity(parseInt(urlQuantity));
        }
        if (urlDate) {
            // TODO: Since the time is not passed, it is necessary to check if the slots are ok
            setDatePicker(new Date(`${urlDate}T21:00:00-00:00`));
        }
    }, [urlDate, urlQuantity]);

    // const [adults, setAdults] = useState(1);

    return (
        <Grid2 className={classes.gridPadre} container translate="no">
            {showLoading ? (
                <div className={classes.loaderContainer}>
                    <div className={classes.loader}>
                        <CircularIndeterminate centered={true} />
                    </div>
                </div>
            ) : (
                ''
            )}
            <Grid2
                className={classes.gridExperience}
                size={12}>
                {showTitle ? (
                    <Grid2 className={classes.gridTitle}>
                        <Typography variant="h3">{experience?.name}</Typography>
                    </Grid2>
                ) : (
                    ''
                )}

                {showQuantity ? (
                    <Grid2 sx={{marginTop: '8px'}}>
                        <PaxComponent
                            setAdults={setQuantity}
                            adults={quantity}
                        />
                    </Grid2>
                ) : (
                    ''
                )}
                {resources.length ? (
                    <ResourceGrid
                        resources={resources}
                        quantity={quantity}
                        setResourceSelected={setResourceSelected}
                        resourceSelected={resourceSelected}
                    />
                ) : (
                    ''
                )}

                <ExperienceDatePicker
                    setDatePicker={setDatePicker}
                    setVisibleRange={setVisibleRange}
                    quantity={quantity}
                    datePicker={datePicker}
                    slots={slots}
                    showPrice={showPrice}
                    experience={experience}
                    setSelectedSlot={setSelectedSlot}
                />
            </Grid2>
        </Grid2>
    );
};
export default ExperienceAvailabilityPageComponent;
