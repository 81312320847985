import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider as MuiPickerProvider } from '@mui/x-date-pickers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import theme from './core/theme';
import Router from './core/routing';

import { AuthProvider } from './core/contexts/auth';
import { ExperiencerProvider } from './core/contexts/experiencer';
import { ContractProvider } from './core/contexts/contract';
import { ExperiencesProvider } from './core/contexts/experience';
import { ScheduleProvider } from './core/contexts/schedule';
import { BookingProvider } from './core/contexts/booking';
import { LocalizationProvider } from './core/contexts/localization';
import { HostProvider } from './core/contexts/host';
import { StripeProvider } from 'core/contexts/stripe';

import getDatepickerLocale from './core/locales/getDatepickerLocale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const App = () => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <MuiPickerProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={getDatepickerLocale()}>
                    <LocalizationProvider>
                        <AuthProvider>
                            <StripeProvider>
                                <ExperiencerProvider>
                                    <HostProvider>
                                        <ContractProvider>
                                            <ExperiencesProvider>
                                                <ScheduleProvider>
                                                    <BookingProvider>
                                                        <Router />
                                                    </BookingProvider>
                                                </ScheduleProvider>
                                            </ExperiencesProvider>
                                        </ContractProvider>
                                    </HostProvider>
                                </ExperiencerProvider>
                            </StripeProvider>
                        </AuthProvider>
                    </LocalizationProvider>
                    <ToastContainer />
                </MuiPickerProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

App.displayName = 'App';

export default App;
