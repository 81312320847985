import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
    Grid2,
    useMediaQuery,
    Box,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// TODO: Props
import {
    moods,
    getTranslatedName as getModusTranslatedName,
    isIdPresent as isModusIdPresent,
} from 'core/libs/import/Moods';
import ModusChip from 'core/libs/core-ui/components/Chip';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';
import Dialog from 'core/libs/core-ui/components/Dialog';
import ExperienceDetail from '../ExperienceDetail';
import { useStyles } from './styles';

const ExperienceCard = ({
    experience,
    experienceContract,
    useTranslation,
    experienceService,
    onBooked = () => {},
}) => {
    const classes = useStyles();
    const history = useHistory();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const [openDialog, setOpenDialog] = useState(false);
    const { t: translate, i18n } = useTranslation();
    const i18nLang = i18n.language;

    const {
        name: experienceName,
        image: experienceImage,
        id: experienceId,
        location: experienceLocation,
        moduses: experienceModuses,
        currency: experienceCurrency,
        fromPrice: experienceFromPrice,
        originalPrice: experienceOriginalPrice,
        discount: experienceDiscount,
    } = experience;

    const handleCardClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleBookClick = () => {
        onBooked();
        history.push(
            `/booking/new/${experienceId}${
                experienceContract ? '/code/' + experienceContract : ''
            }`
        );
    };

    return (
        <Box>
            <Card key={experienceId} className={classes.root}>
                <CardActionArea onClick={handleCardClick}>
                    <Grid2 className={classes.cardHeader}>
                        <CardMedia
                            component="img"
                            alt={experienceName}
                            height="200"
                            image={experienceImage}
                            title={experienceName}
                        />
                        <Grid2 className={classes.overlay}>
                            <Typography
                                variant="body2"
                                component="h5"
                                className={classes.overLayText}>
                                {experienceName}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <CardContent
                        className={isMobile ? {} : classes.cardContent}>
                        <Box className={classes.priceContent}>
                            {experienceDiscount && experienceOriginalPrice ? (
                                <Box className={classes.priceBadge}>
                                    <Typography
                                        className={classes.priceWrapRed}>
                                        {experienceCurrency}{' '}{MoneyFormattedUtils(
                                            experienceOriginalPrice,
                                            i18nLang,
                                        )}
                                    </Typography>
                                </Box>
                            ) : null}
                            {
                                experienceFromPrice > 0 ? (
                                    <Box className={classes.priceWrapGreen}>
                                        {/* TODO: Eliminar el stlye cuando se eliminen los styles globales de typography*/}
                                        <Typography style={{ fontWeight: 'bold' }}>
                                            {experienceDiscount
                                                ? `${experienceCurrency} ${MoneyFormattedUtils(
                                                    experienceFromPrice,
                                                    i18nLang,
                                                )} (${experienceDiscount
                                                    .toFixed(1)
                                                    .replace(/\.?0+$/, '')}% OFF)`
                                                : `${experienceCurrency} ${MoneyFormattedUtils(
                                                        experienceFromPrice,
                                                        i18nLang,
                                                    )}`
                                            }
                                        </Typography>
                                    </Box>
                                ) : 'FREE'
                            }
                        </Box>
                        <Grid2
                            container
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}>
                            <Grid2>
                                <LocationOnIcon />
                            </Grid2>
                            <Grid2>
                                <Typography variant="body2">
                                    {experienceLocation}
                                </Typography>
                            </Grid2>
                        </Grid2>
                        {experienceModuses.length > 0 &&
                            experienceModuses.some((m) =>
                                isModusIdPresent(m, moods)
                            ) && (
                                <Grid2 container wrap="nowrap">
                                    <Grid2
                                        size={1}
                                        spacing={1}
                                        className={classes.checkIcon}>
                                        <CheckIcon />
                                    </Grid2>
                                    <Grid2
                                        size={{xs: 6, sm: 6, md: 6, lg: 11, xl: 11}}
                                        className={classes.modusesWrapper}>
                                        {experienceModuses
                                            .slice(0, 3)
                                            .map((modusId) => {
                                                if (
                                                    !isModusIdPresent(
                                                        modusId,
                                                        moods
                                                    )
                                                ) {
                                                    return null;
                                                }

                                                return (
                                                    <>
                                                        <ModusChip
                                                            key={modusId}
                                                            color="gray"
                                                            className={
                                                                classes.modusChip
                                                            }
                                                            label={getModusTranslatedName(
                                                                modusId,
                                                                i18nLang,
                                                                moods
                                                            )}
                                                        />
                                                    </>
                                                );
                                            })}
                                        {experienceModuses.length > 3 && (
                                            <span className={classes.moreChips}>
                                                ...
                                            </span>
                                        )}
                                    </Grid2>
                                </Grid2>
                            )}
                    </CardContent>
                    <CardActions>
                        <Button
                            className={classes.buttonWrap}
                            onClick={handleBookClick}>
                            <Typography
                                variant="h6"
                                className={classes.buttonBook}>
                                {translate('experienceCard.book')}
                            </Typography>
                        </Button>
                    </CardActions>
                </CardActionArea>
            </Card>
            <Dialog
                open={openDialog}
                handleClose={handleCloseDialog}
                hideActions={true}
                hideTitle={true}
                paperProps={{
                    style: { maxWidth: 'none' }, // Custom styles or props for the Paper component
                }}
                dialogContent={
                    <ExperienceDetail
                        experience={experience}
                        experienceContract={experienceContract}
                        useTranslation={useTranslation}
                        experienceService={experienceService}
                    />
                }
            />
        </Box>
    );
};

export default ExperienceCard;
