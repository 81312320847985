import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function AlertDialog({ paperProps, ...props }) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ ...paperProps }}
      >
        <DialogTitle id="alert-dialog-title" hidden={props.hideTitle || false} >{props.dialogTitle || "Dialog"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.dialogContent}
          </DialogContentText>
        </DialogContent>
        <div hidden={props.hideActions || false}>
        <DialogActions>
          <div hidden={props.hideBack || false}>
            <Button onClick={props.handleClose} color="primary">
              {props.labelBack || "Disagree"}
            </Button>
          </div>
          <div hidden={props.hideSubmit || false}>
          <Button onClick={props.handleSubmit} color="primary" autoFocus>
            {props.labelNext || "Agree"}
          </Button>
          </div>
        </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}