import { TextField } from '@mui/material';
import { useStyles } from './styles';

const SearchBox = ({ onChange, value, style, label }) => {
  const classes = useStyles();
  return (
    <TextField
      id="outlined-basic"
      label={label}
      variant="outlined"
      style={{
        width: '20rem',
        ...style,
      }}
      className={classes.globalBookingsSearchBox}
      onChange={(event) => {
        onChange(event);
      }}
      value={value}
    />
  );
};

export default SearchBox;
